<div class="container">
  <div class="row">
    <div class="col-3">
      <a href="https://cplc.ch/Fichiers/CDTravailPassion2025.zip" title="CD de travail Passion 2025"
        ><div class="download">CD de travail Passion 2025</div></a
      >
    </div>
    <div class="col-3">
      <a href="https://cplc.ch/Fichiers/ConcertCasino2024.zip" title="Concert Casino 2024"
        ><div class="download">Concert Casino 2024</div></a
      >
    </div>
    <div class="col-3">
      <a href="https://cplc.ch/Fichiers/CDTravail2024.zip" title="CD de travail 2024"
        ><div class="download">CD de travail 2024</div></a
      >
    </div>
    <div class="col-3">
      <a href="https://cplc.ch/Fichiers/CDTravail2023.zip" title="CD de travail 2023"
        ><div class="download">CD de travail 2023</div></a
      >
    </div>

    <div class="w-100 mb-4"></div>

    <div class="col-3">
      <a href="https://cplc.ch/Fichiers/CDTravail2022.zip" title="CD de travail 2022"
        ><div class="download">CD de travail 2022</div></a
      >
    </div>
    <div class="col-3">
      <a href="https://cplc.ch/Fichiers/CDTravail2021.zip" title="CD de travail 2021"
        ><div class="download">CD de travail 2021</div></a
      >
    </div>
    <div class="col-3">
      <a href="https://cplc.ch/Fichiers/CDTravail2019.zip" title="CD de travail 2019"
        ><div class="download">CD de travail 2019</div></a
      >
    </div>
    <div class="col-3">
      <a href="https://cplc.ch/Fichiers/CDTravail2018.zip" title="CD de travail 2018"
        ><div class="download">CD de travail 2018</div></a
      >
    </div>

    <div class="w-100 mb-4"></div>

    <div class="col-3">
      <a href="https://cplc.ch/Fichiers/CDTravail2017.zip" title="CD de travail 2017"
        ><div class="download">CD de travail 2017</div></a
      >
    </div>
    <div class="col-3">
      <a href="https://cplc.ch/Fichiers/ConcertCasino2016.zip" title="Concert Casino 2016"
        ><div class="download">Concert Casino 2016</div></a
      >
    </div>
    <div class="col-3">
      <a href="https://cplc.ch/Fichiers/CDTravail2015.zip" title="CD de travail 2015"
        ><div class="download">CD de travail 2015</div></a
      >
    </div>
  </div>
</div>
